import React from 'react';
import { Column, Row } from 'simple-flexbox';
import { StyleSheet, css } from 'aphrodite';
// import LineChart from "react-svg-line-chart";
import { Form } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';

export const formatDateFull = (date) => {
  var options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  };
  let dateToFormat = new Date(date);
  let formmateddate = dateToFormat.toLocaleDateString('en-GB', options);
  return formmateddate;
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #DFE0EB',
    borderRadius: 4,
    cursor: 'pointer',
  },
  graphContainer: {
    marginTop: 24,
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
  },
  graphSection: {
    padding: 24,
  },
  graphSubtitle: {
    fontFamily: 'Muli',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.1px',
    color: '#9FA2B4',
    marginTop: 4,
    marginRight: 8,
  },
  graphTitle: {
    fontFamily: 'Muli',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 19,
    lineHeight: '24px',
    letterSpacing: '0.4px',
    color: '#252733',
  },
  legendTitle: {
    fontFamily: 'Muli',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 12,
    lineHeight: '15px',
    letterSpacing: '0.1px',
    color: '#9FA2B4',
    marginLeft: 8,
  },
  separator: {
    backgroundColor: '#DFE0EB',
    width: 1,
    minWidth: 1,
  },
  statContainer: {
    borderBottom: '1px solid #DFE0EB',
    padding: '24px 32px 24px 32px',
    height: 'calc(114px - 48px)',
    ':last-child': {
      border: 'none',
    },
  },
  stats: {
    borderTop: '1px solid #DFE0EB',
    width: '100%',
  },
  statTitle: {
    fontFamily: 'Muli',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 16,
    lineHeight: '22px',
    letterSpacing: '0.3px',
    textAlign: 'center',
    color: '#9FA2B4',
    whiteSpace: 'nowrap',
    marginBottom: 6,
  },
  statValue: {
    fontFamily: 'Muli',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '30px',
    letterSpacing: '0.3px',
    textAlign: 'center',
    color: '#252733',
  },
});


class TodayTrendsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.chartReference = React.createRef();
    // console.log('PROPS DATA', this.props)
    
    this.monthLbls = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    this.dashData = [];
    for (let idx = 0; idx < props?.todayGame?.length; idx++) {
      const game = props.todayGame[idx];
      const prodName = game.product['name'].replace(/ /g, '_').toLowerCase();

      this.dashData.push({
        "name": prodName,
        "detail": [
          {
            id: 1,
            title: 'Current Winning numbers',
            value: this.props.winningNumbers
              ? this.props.winningNumbers?.toString()
              : 'No winning numbers',
          },
          {
            id: 2,
            title: 'Today Game',
            value: game
              ? game?.product.name +
                ` (${game?.event_code})`
              : 'No game today',
          },
          {
            id: 3,
            title: 'Total winners today',
            value: this.props.todayWinners,
          },
        ]
      })
      // console.log("GAME", el)
    }

    this.state = {
      graphtype: 'winnings',
      line_data: {
        labels: this.monthLbls,
        datasets: [
          {
            label: 'Dataset',
            data: this.props?.data,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
        ],
      },
      reportTypes: [
        { id: 'winnings', report_item: 'Winnings' },
        { id: 'payout', report_item: 'Payouts' },
      ],

      dashboardData: this.dashData,
    };

    // console.log("STAT DATA", this.state, this.state.dashboardData.length)
    // console.log("DASH DATA", this.dashData, this.dashData.length)
  }

  changedata(graphtype) {
    const chart = this.chartReference.current.chartInstance;
    if (graphtype === 'winnings') {
      chart.data.datasets[0].data = this.props.data;
      chart.update();
    } else {
      // console.log("I was called");
      chart.data.datasets[0].data = this.props.payoutdata;
      chart.update();
    }
  }

  render() {
    return (
      <>        
        {/* {this?.dashData.length > 0 ? (
            this.dashData.map((gv) => ( */}
        {this.state?.dashboardData.length > 0 ? (
          this.state.dashboardData.map((gv) => (
              <>

                <Row
                  flexGrow={1}
                  className={css(styles.container)}
                  horizontal="center"
                  breakpoints={{ 1024: 'column' }}
                  >
                  <Column
                    wrap
                    flexGrow={7}
                    flexBasis="735px"
                    className={css(styles.graphSection)}
                    breakpoints={{
                      1024: { width: 'calc(100% - 48px)', flexBasis: 'auto' },
                    }}
                    >
                    
                    <Row wrap horizontal="space-between">
                      <Column>
                        <span className={css(styles.graphTitle)}>
                          Winners Growth Graph
                        </span>
                        <span className={css(styles.graphSubtitle)}>
                          {formatDateFull(new Date())}
                        </span>
                      </Column>
                      <div style={{ width: '30%', marginBottom: 10 }}>
                        <Form.Control
                          required
                          as="select"
                          type="select"
                          onInput={(e) => {
                            this.changedata(e.target.value);
                          }}
                          style={{
                            backgroundColor: 'white',
                            color: '#37517e',
                            border: 'solid',
                            width: '100%',
                            height: '45px',
                          }}
                        >
                          {this.state.reportTypes?.map((m, key) => (
                            <option key={key} value={m.id}>
                              {m.report_item}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                    </Row>
                    
                    <div className={css(styles.graphContainer)}>
                      <Line ref={this.chartReference} data={this.state.line_data} />
                    </div>
                  </Column>
                  <Column className={css(styles.separator)} breakpoints={{ 1024: { display: 'none' } }}>&nbsp;</Column>
                  <Column
                    flexGrow={3}
                    flexBasis="342px"
                    breakpoints={{ 1024: css(styles.stats) }}
                  >
                    {gv.detail.map((item, key) => (
                      <Column
                        key={key}
                        flexGrow={1}
                        className={css(styles.statContainer)}
                        vertical="center"
                        horizontal="center"
                      >
                        <span className={css(styles.statTitle)}>{item.title}</span>
                        <span className={css(styles.statValue)}>{item.value}</span>
                      </Column>
                    ))}
                  </Column>
                </Row>

                <div style={{clear: "both"}}>&nbsp;</div>
              </>                        
          ))
        ) : (<><div><Row wrap horizontal="space-between"><p>No game(s) available</p></Row></div></>)}

      {/* 
      <Row
        flexGrow={1}
        className={css(styles.container)}
        horizontal="center"
        breakpoints={{ 1024: 'column' }}
        >
        <Column
          wrap
          flexGrow={7}
          flexBasis="735px"
          className={css(styles.graphSection)}
          breakpoints={{
            1024: { width: 'calc(100% - 48px)', flexBasis: 'auto' },
          }}
          >
          <!-- Header row -->
          <Row wrap horizontal="space-between">
            <Column>
              <span className={css(styles.graphTitle)}>
                Winners Growth Graph
              </span>
              <span className={css(styles.graphSubtitle)}>
                {formatDateFull(new Date())}
              </span>
            </Column>
            <div style={{ width: '30%', marginBottom: 10 }}>
              <Form.Control
                required
                as="select"
                type="select"
                onInput={(e) => {
                  this.changedata(e.target.value);
                }}
                style={{
                  backgroundColor: 'white',
                  color: '#37517e',
                  border: 'solid',
                  width: '100%',
                  height: '45px',
                }}
              >
                {this.state.reportTypes?.map((m, key) => (
                  <option key={key} value={m.id}>
                    {m.report_item}
                  </option>
                ))}
              </Form.Control>
            </div>
          </Row>
          <!-- //Header row -->

          <div className={css(styles.graphContainer)}>
            <Line ref={this.chartReference} data={this.state.line_data} />
          </div>
        </Column>
        <Column className={css(styles.separator)} breakpoints={{ 1024: { display: 'none' } }}><div /></Column>
        <Column
          flexGrow={3}
          flexBasis="342px"
          breakpoints={{ 1024: css(styles.stats) }}
        >
          {this.state.dashboardData.map((item, key) => (
            <Column
              key={key}
              flexGrow={1}
              className={css(styles.statContainer)}
              vertical="center"
              horizontal="center"
            >
              <span className={css(styles.statTitle)}>{item.title}</span>
              <span className={css(styles.statValue)}>{item.value}</span>
            </Column>
          ))}
        </Column>
      </Row>
      */}

      </>
    );
  }
}

export default TodayTrendsComponent;
