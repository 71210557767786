export const getToday = () => {
  let date = new Date();

  return date.toISOString().substring(0, 10);
};

export const formatDateFull = (date) => {
  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
  };
  let dateToFormat = new Date(date);
  let formmateddate = dateToFormat.toLocaleDateString("en-GB", options);
  return formmateddate;
};

export const formatDateFullWithTime = (date) => {
  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
  };
  let dateToFormat = new Date(date);
  let formmateddate =
    dateToFormat.toLocaleDateString("en-GB", options) +
    " " +
    dateToFormat.getHours() +
    ":" +
    dateToFormat.getMinutes() +
    getAMPM(dateToFormat.getHours());
  return formmateddate;
};

const getAMPM = (hour) => {
  return hour > 12 ? " PM" : " AM";
};

export const formatCurrency = (premium) => {
  let result = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "GHS",
  }).format(premium);
  return result;
};

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const formatAmount = (premium) => {
  let result = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "GHS",
  }).format(premium);
  return result;
};

export const validateIDCard = (idCardNumber) => {
  if (idCardNumber?.length === 15) {
    // Check the total length
    if (idCardNumber?.startsWith("GHA-")) {
      return true;
    } else if (idCardNumber?.startsWith("NRG-")) {
      return true;
    }
  }
  return false;
};
