import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import MUIDataTable from "mui-datatables";
import { formatDateFull } from "../../utils";
import Spinner from "../../components/Spinner";
import "./index.css";
import { BiFilter } from "react-icons/bi";
import { GET_GAME_OF_DAY_NEW } from "../../Graphql/Queries";
import { SET_GAME_OF_DAY, TERMINATE_GAME } from "../../Graphql/Mutations";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../components/Notification";
import CloseGame from "../../components/CloseGame";
import AdminModal from "../../components/Modal";
import { CLOSE_GAME } from "../../Graphql/Mutations";

import TerminateGameComponent from "../../components/terminateGame";

const Index = () => {
  // const [loading, setLoading] = useState(false);
  const [games, setGames] = useState([]);
  const [game_id, setGameId] = useState();
  const [showDetailModal, setShowMDetailModal] = useState(false);
  const handleclose2 = () => setShowMDetailModal(false);
  const [selectedGameId, setSelectedGameId] = useState();
  const [showDetailModal2, setShowMDetailModal2] = useState(false);
  const handleclose3 = () => setShowMDetailModal2(false);

  const getDayOfWeek = () => {
    const dayOfWeekName = new Date().toLocaleString("default", {
      weekday: "long",
    });
    let f = dayOfWeekName.substring(0, 3);
    return f.toLowerCase();
  };

  const { loading: eventLoading, refetch } = useQuery(GET_GAME_OF_DAY_NEW, {
    variables: {
      schedule_day: [getDayOfWeek().toString()],
    },

    onCompleted: (data) => {
      setGames(data.game);
    },

    onError: () => {
      ErrorNotification("Failed to fetch game of the day");
    },
  });

  const [setupGameofDay] = useMutation(SET_GAME_OF_DAY, {
    onCompleted: () => {
      refetch();
      SuccessNotification("You have successfully disabled game of day ");
    },
    onError: () => ErrorNotification("Failed to set game of the day"),
  });

  const [terminateGame] = useMutation(TERMINATE_GAME, {
    onCompleted: () => {
      refetch();
      setShowMDetailModal2(false);
      SuccessNotification("You have successfully terminated game ");
    },
    onError: () => ErrorNotification("Failed terminate game"),
  });

  const [closeGameofDay] = useMutation(CLOSE_GAME, {
    onCompleted: () => {
      setShowMDetailModal(false);
      refetch();
      SuccessNotification("You have successfully closed the game ");
    },
    onError: () => ErrorNotification("An error occurred while closing game"),
  });

  const checkTime = () => {
    setShowMDetailModal(true);
  };

  useEffect(() => {
    if (games) {
      setGameId(games[0]?.id);
    }
  }, [games]);

  const getDatafromRow = (value) => {
    // if (selectedBtnId === value) {

    let id = value?.tableData[0][0];

    setupGameofDay({
      variables: {
        gameId: id,
        action: true,
      },
    });
  };

  const getDatafromRow2 = (value) => {
    let id = value?.tableData[0][0];
    setSelectedGameId(id);
  };

  const options = {
    filterType: "checkbox",
    filter: false,
    print: false,
    customToolbarSelect: () => {},
  };

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "closed_at",
      label: "id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "event_code",
      label: "Event number",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: "relative", left: 50 }}>{val}</p>;
        },
      },
    },
    {
      name: "product",
      label: "Game Name",

      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: "relative", left: 30 }}>{val.name}</p>;
        },
      },
    },
    {
      name: "product",
      label: "Event day",

      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: "relative", left: 30 }}>
              {val.schedule_day[0]}
            </p>
          );
        },
      },
    },
    {
      name: "schedule_start",
      label: "Scheduled date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (val) => {
          return <div>{formatDateFull(val)}</div>;
        },
      },
    },
    {
      name: "run_start",
      label: "Start time",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "run_end",
      label: "End time",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "game_of_day",
      label: "Game of day",
      align: "right",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <div>
              {val == true ? (
                <div
                  className="px-3 py-1 mt-2 divheight bg-greenLight rounded-12 flex items-center justify-center"
                  style={{ marginLeft: 20 }}
                >
                  <p className="text-green mt-4">{"Yes"}</p>
                </div>
              ) : val === false ? (
                <div
                  className="px-3  py-1 mt-2 divheight bg-orangeLight rounded-12 flex items-center justify-center"
                  style={{ marginLeft: 20 }}
                >
                  <p className="text-orange mt-4">{"No"}</p>
                </div>
              ) : null}
            </div>
          );
        },
      },
    },
    {
      name: "action",
      label: "Actions",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (tableMeta) => {
          return (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                style={{ marginRight: 5 }}
                disabled={tableMeta?.rowData[1] == null ? false : true}
                className="btn btn-outline-success font-loader"
                onClick={(e) => {
                  e.preventDefault();
                  checkTime();
                  getDatafromRow2(tableMeta);
                }}
              >
                Close game
              </button>

              <button
                style={{ marginRight: 5 }}
                className="btn btn-outline-warning font-loader"
                onClick={(e) => {
                  e.preventDefault();
                  getDatafromRow(tableMeta);
                }}
              >
                Disable game
              </button>

              <button
                style={{ marginRight: 5 }}
                disabled={tableMeta?.rowData[1] == null ? false : true}
                className="btn btn-outline-danger font-loader"
                onClick={(e) => {
                  e.preventDefault();
                  setShowMDetailModal2(true);
                  getDatafromRow2(tableMeta);
                }}
              >
                Terminate game
              </button>
            </div>
          );
        },
      },
    },
  ];

  // const eventData = [
  //   {
  //     id: 1,
  //     event_number: "1238392",
  //     event_day: "Sunday",
  //     event_name: "Twa di",
  //     start_time: "5:00am",
  //     end_time: "6:30pm",
  //     schedule_start: "2022-12-13",
  //   },
  //   {
  //     id: 2,
  //     event_number: "1238392",
  //     event_day: "Sunday",
  //     event_name: "Friday Bonanza",
  //     start_time: "5:00am",
  //     end_time: "6:30pm",
  //     schedule_start: "2022-12-14",
  //   },

  //   {
  //     id: 2,
  //     event_number: "67892",
  //     event_day: "Sunday",
  //     start_time: "5:00am",
  //     end_time: "6:30pm",
  //     schedule_start: "2022-12-17",
  //     event_name: "Friday Bonanza",
  //   },
  // ];

  return (
    <div style={{ padding: 20 }}>
      <AdminModal
        show={showDetailModal}
        close={!showDetailModal}
        title={"Close game here"}
        body={<CloseGame id={game_id} closeGameofDay={closeGameofDay} />}
        handleClose={handleclose2}
      />

      <AdminModal
        show={showDetailModal2}
        close={!showDetailModal2}
        title={"Terminate game"}
        body={
          <TerminateGameComponent
            id={selectedGameId ?? game_id}
            terminateGame={terminateGame}
            setShowMDetailModal2={setShowMDetailModal2}
          />
        }
        handleClose={handleclose3}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            float: "right",
            width: "50%",
          }}
        >
          <div style={{ marginRight: 15, width: "100%", marginBottom: 10 }}>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">From</span>
              </div>
              <input
                type="date"
                className="form-control"
                placeholder="Some text"
              />
            </div>
          </div>

          <div style={{ marginRight: 15, width: "100%", marginBottom: 10 }}>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">To</span>
              </div>
              <input
                type="date"
                className="form-control"
                placeholder="Some text"
              />
            </div>
          </div>

          <div style={{ marginRight: 15, width: "100%", marginBottom: 10 }}>
            <button className="btn btn-outline-info" style={{ height: 36 }}>
              <BiFilter size={21} /> Filter
            </button>
          </div>
        </div>
      </div>

      {eventLoading ? (
        <div
          style={{
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <MUIDataTable
          data={games && games}
          title={""}
          columns={columns}
          options={options}
          // onRowClick={handleRowClick}
        />
      )}
    </div>
  );
};

export default Index;
