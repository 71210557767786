import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_ADMINS } from '../../Graphql/Queries';
import { CircularProgress } from '@material-ui/core';
import AdminCard from './AdminCard';
import { BiArrowBack } from 'react-icons/bi';
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { ErrorNotification } from '../../components/Notification';

const AdminsView = () => {
  const [allAdmins, setAllAdmins] = useState([]);
  // const history = useHistory();
  const navigate = useNavigate();

  // const formik = useFormik({
  //   initialValues: {
  //     email: '',
  //     password: '',
  //     phone_number: '',
  //     first_name: '',
  //     middle_name: '',
  //     surname: '',
  //     user_type: 'admin',
  //     department_id: 'b70e78b0-07c3-4b84-9d79-959a0a03a110',
  //     platform: 'web'
  //   },

  //   onSubmit: values => {
  //     api
  //       .post(`/admin/signup`, values)
  //       .then(response => {})
  //       .catch(error => {
  //         console.log(error)
  //       })
  //   }
  // })

  const { loading: adminDataLoading } = useQuery(GET_ALL_ADMINS, {
    onCompleted: (data) => {
      setAllAdmins(data.admin);
    },

    onError: () => {
      ErrorNotification('Failed to load admin data');
    },
  });

  return (
    <div>
      <div
        style={{
          padding: 15,
        }}
      >
        <BiArrowBack
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          size={50}
        />
      </div>
      {/* <Tabs
        defaultActiveKey='createAdmin'
        id='uncontrolled-tab-example'
        className='mb-3'
      >
        <Tab eventKey='createAdmin' title='Create Admins'>
          <div
            style={{ display: 'flex', flexDirection: 'column', padding: 15 }}
          >
            <h2>Create Admin</h2>

            <Form onSubmit={formik.handleSubmit}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Form.Group as={Col} md='4' style={{ margin: 10 }}>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    onChange={formik.handleChange}
                    name='first_name'
                    type='text'
                  ></Form.Control>
                </Form.Group>
                <Form.Group as={Col} md='4' style={{ margin: 10 }}>
                  <Form.Label>Middle Name</Form.Label>
                  <Form.Control
                    onChange={formik.handleChange}
                    name='middle_name'
                    type='text'
                  ></Form.Control>
                </Form.Group>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Form.Group as={Col} md='4' style={{ margin: 10 }}>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    onChange={formik.handleChange}
                    name='surname'
                    type='text'
                  ></Form.Control>
                </Form.Group>
                <Form.Group as={Col} md='4' style={{ margin: 10 }}>
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    onChange={formik.handleChange}
                    name='phone_number'
                    type='text'
                  ></Form.Control>
                </Form.Group>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Form.Group as={Col} md='4' style={{ margin: 10 }}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    onChange={formik.handleChange}
                    name='email'
                    type='email'
                  ></Form.Control>
                </Form.Group>
                <Form.Group as={Col} md='4' style={{ margin: 10 }}>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    onChange={formik.handleChange}
                    name='password'
                    type='passwor'
                  ></Form.Control>
                </Form.Group>
              </div>
              <Button
                style={{ position: 'relative', left: 25, marginTop: 15}}
                className='btn btn-primary'
                type='submit'
              >
                Create Admin
              </Button>
            </Form>
          </div>
        </Tab>
        <Tab eventKey='viewAdmins' title='View Admins'>
            
        </Tab>
      </Tabs> */}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '75%',
          flexWrap: 'wrap',
          padding: 15,
        }}
      >
        {adminDataLoading ? (
          <CircularProgress />
        ) : (
          allAdmins?.map((admin) => {
            return (
              <AdminCard
                key={admin.id}
                admin={admin}
                email={admin.email}
                firstName={admin.first_name}
                lastName={admin.last_name}
                phoneNumber={admin.phone_number}
                status={admin.status}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default AdminsView;
