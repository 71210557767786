import React, { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { SuccessNotification, ErrorNotification } from "./Notification";

function CloseGame({ id }) {
  const [OTP1, setOTP1] = useState("");
  const [OTP2, setOTP2] = useState("");
  const [OTP3, setOTP3] = useState("");
  const [OTP4, setOTP4] = useState("");
  const [OTP5, setOTP5] = useState("");
  const [OTP6, setOTP6] = useState("");
  const token = localStorage.getItem("accesstoken");

  const loading = false;

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  const addValue = (box, value) => {
    if (box == 1 && value <= 96 && value > 0) {
      setOTP1(value);
    } else if (box == 2 && value <= 96 && value > 0) {
      setOTP2(value);
    } else if (box == 3 && value <= 96 && value > 0) {
      setOTP3(value);
    } else if (box == 4 && value <= 96 && value > 0) {
      setOTP4(value);
    } else if (box == 5 && value <= 96 && value > 0) {
      setOTP5(value);
    } else if (box == 6 && value <= 96 && value > 0) {
      setOTP6(value);
    }
  };

  const close = async () => {
    if (
      OTP1 === "" ||
      OTP2 === "" ||
      OTP3 === "" ||
      OTP4 === "" ||
      OTP5 === "" ||
      OTP6 === ""
    ) {
      ErrorNotification("Fill all the spaces");
    } else {
      let otpSet = [
        parseInt(OTP1),
        parseInt(OTP2),
        parseInt(OTP3),
        parseInt(OTP4),
        parseInt(OTP5),
        parseInt(OTP6),
      ];

      let payload = {
        game_id: id,
        results: otpSet,
      };

      try {
        const res = await axiosInstance.post(
          "api/v1/admin/game/close",
          payload,
        );

        if (res?.status === 200) {
          SuccessNotification(
            res?.data?.message ?? "You have successfully closed the game ",
          );
          window.location.reload();
        }
      } catch (error) {
        ErrorNotification(
          error?.messge ?? "An error occured while closing game",
        );
      }
    }
  };

  const raiseAlert = () => {
    Swal.fire({
      title: "Are you sure you want to close this game",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes,accept",
    }).then((result) => {
      if (result.isConfirmed) {
        close();
        // try {
        // } catch (error) {
        //   console.log(error);
        //   ErrorNotification(
        //     error?.message ?? "An error occured while closing game"
        //   );
        // }
      }
    });
  };

  return (
    <div
      style={{
        height: "500px",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1>{"Please enter winning numbers here"}</h1>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <input
          value={OTP1}
          onChange={(e) => addValue(1, e.target.value)}
          type="text"
          style={{
            width: 50,
            margin: 10,
            paddingLeft: 15,

            height: 50,
            marginBottom: 20,
            borderRadius: 5,
          }}
        />
        <input
          value={OTP2}
          onChange={(e) => addValue(2, e.target.value)}
          type="text"
          style={{
            width: 50,
            margin: 10,
            paddingLeft: 15,

            height: 50,
            marginBottom: 20,
            borderRadius: 5,
          }}
        />
        <input
          value={OTP3}
          onChange={(e) => addValue(3, e.target.value)}
          type="text"
          style={{
            width: 50,
            margin: 10,
            paddingLeft: 15,

            height: 50,
            marginBottom: 20,
            borderRadius: 5,
          }}
        />
        <input
          value={OTP4}
          onChange={(e) => addValue(4, e.target.value)}
          type="text"
          style={{
            width: 50,
            margin: 10,
            paddingLeft: 15,

            height: 50,
            marginBottom: 20,
            borderRadius: 5,
          }}
        />
        <input
          value={OTP5}
          onChange={(e) => addValue(5, e.target.value)}
          type="text"
          style={{
            width: 50,
            margin: 10,
            paddingLeft: 15,

            height: 50,
            marginBottom: 20,
            borderRadius: 5,
          }}
        />
        <input
          value={OTP6}
          onChange={(e) => addValue(6, e.target.value)}
          type="text"
          style={{
            width: 50,
            margin: 10,
            paddingLeft: 15,

            height: 50,
            marginBottom: 20,
            borderRadius: 5,
          }}
        />
      </div>

      <div style={{ width: "10%" }}>
        <button
          onClick={() => raiseAlert()}
          style={{
            width: "100%",
            marginBottom: 10,
          }}
          className="btn btn-primary"
        >
          {loading ? "Loading..." : "Close Game"}
        </button>
      </div>
    </div>
  );
}

export default CloseGame;
